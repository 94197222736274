<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters align="center">
          <v-col class="pb-1" cols="12">
            <span class="text-caption light--text">
              {{ $trans(subtitle) | truncate(titleTruncate) }}
            </span>
          </v-col>
          <v-col cols="12" align-self="center">
            <v-row no-gutters align="center">
              <v-col cols="12" class="mb-2">
                <span class="text-h4 mr-2">
                  {{ title | truncate(titleTruncate) }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "AppBarRoute",
  computed: {
    titleTruncate() {
      return this.$vuetify.breakpoint.width > 960 ? 70 : 35;
    },
    title() {
      const { name } = this.$route;

      if (name === "add_service") {
        return this.$route.params.duplicateId
          ? this.$trans("duplicate_service")
          : this.$trans("add_service");
      }

      return this.$trans(name);
    },
    subtitle() {
      return this.$route.meta.title || "section_admin_panel";
    },
  },
};
</script>
